const certificateBuilder = {
  buildData({ detail, identity, profile, srcLogo }) {
    const fullName = `${profile.firstName} ${profile.lastName} (${profile.email})`;
    const membershipExpiration = formatTimestamp(
      identity.subscription.active_until * 1000,
    );
    const fileName = detail.file_name ? detail.file_name : undefined;
    const receipts = `${detail.total_received_receipts}/${detail.recipients.length}`;
    return {
      process_id: detail.process_id,
      heading: {
        srcLogo: srcLogo,
        name: detail.name,
        fullName: fullName,
      },
      metadata: {
        timestamp: formatTimestamp(detail.tx_timestamp),
        fileName,
        hash: detail.hash,
        txHash: detail.tx_hash,
        documentId: detail.document_id,
        receipts,
      },
      certification: {
        timestamp: formatTimestamp(detail.tx_timestamp),
        membershipExpiration: membershipExpiration,
      },
    };
  },
  buildHtml(data, hasVault = false) {
    const heading = getHeading(data.heading, hasVault);
    const metadata = getMetadata(data.metadata, hasVault);
    const certification = getCertification(data.certification, hasVault);
    return `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>Certificate ${data.processId}}</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,300&family=Roboto+Mono&display=swap"
            rel="stylesheet"
          />
          <style>
            * {
              box-sizing: border-box;
            }
            body {
              font-family: 'Open Sans', sans-serif;
              margin: 0;
            }
            #heading {
              text-align: center;
            }
            #heading img {
              height: 140px;
              width: 140px;
            }
            #heading h1 {
              padding: 16px 0;
            }
            #heading h2 {
              padding: 8px 0;
            }
            #heading p {
              font-style: italic;
            }
            #metadata,
            #certification {
              margin: 32px 8px;
            }
            #metadata ul {
              list-style-type: none;
            }
            #metadata ul li {
              padding: 4px 0;
              font-size: 0.9rem;
              overflow-wrap: break-word;
            }
            #certification p {
              padding: 8px;
              line-height: 1.5rem;
              text-align: center;
            }
            #certification ol li {
              padding: 4px;
              overflow-wrap: break-word;
            }
            .font-bold {
              font-weight: 700;
            }
            .font-monospace {
              font-family: 'Roboto Mono', monospace;
            }
          </style>
        </head>
        <body>
          ${heading}
          ${metadata}
          ${certification}
        </body>
      </html>
    `;
  },
};

export default certificateBuilder;

const formatTimestamp = (timestamp) => new Date(timestamp).toUTCString();

const getHeading = (heading, hasVault) => {
  const certificateHeading = hasVault
    ? '<h2>Certificate of Blockchain notarization and eArchive</h2>'
    : '<h2>Certificate of Blockchain notarization</h2>';
  return `
    <div id="heading">
      <img src="${heading.srcLogo}" alt="Logo Commercio" />
      ${certificateHeading}
      <h1>${heading.name}</h1>
      <p>${heading.fullName}</p>
    </div>
  `;
};

const getMetadata = (metadata, hasVault) => {
  const fileNameMetadata = `
    <li>
      <span class="font-bold">Filename</span>
      <span>:</span>
      <span>${metadata.fileName}</span>
    </li>
  `;
  return `
    <div id="metadata">
      <ul>
        <li>
          <span class="font-bold">Date</span>
          <span>:</span>
          <span>${metadata.timestamp}</span>
        </li>
        ${hasVault ? fileNameMetadata : ''}
        <li>
          <span class="font-bold">Document Hash</span>
          <span>:</span>
          <span class="font-monospace">${metadata.hash}</span>
        </li>
        <li>
          <span class="font-bold">Transaction Hash</span>
          <span>:</span>
          <span class="font-monospace">${metadata.txHash}</span>
        </li>
        <li>
          <span class="font-bold">Messagge UUID</span>
          <span>:</span>
          <span class="font-monospace">${metadata.documentId}</span>
        </li>
        <li>
          <span class="font-bold">Receipts</span>
          <span>:</span>
          <span class="font-monospace">${metadata.receipts}</span>
        </li>
      </ul>
    </div>
  `;
};

const getCertification = (certification, hasVault) => {
  const vaultCertification = `<li>This document has been stored in a secure vault and will be available
      till ${certification.membershipExpiration} or more if storage service will be renewed</li>`;
  return `
    <div id="certification">
      <p>This attest that the document has been notarized in the Commercio
      Blockchain thus proving that the document existed in its current form at
      the time of notarization.<br />With the corrisponding transaction and
      hash you can use this document as proof of certify that:</p>
      <ol type="a">
        <li>This document existed in its current form (Hash footprint) on date ${
          certification.timestamp
        }</li>
        <li>This document has not been altered in any way</li>
        ${hasVault ? vaultCertification : ''}
      </ol>
    </div>
  `;
};

import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const MintViewDashboard = loadView('mint/MintViewDashboard');
const MintViewNew = loadView('mint/MintViewNew');
const MintViewNft = loadView('mint/MintViewNft');
const MintViewWizard = loadView('mint/MintViewWizard');
const MintViewWizardAttributes = loadView(
  'mint/wizard/MintViewWizardAttributes',
);
const MintViewWizardBasicData = loadView('mint/wizard/MintViewWizardBasicData');
const MintViewWizardRecap = loadView('mint/wizard/MintViewWizardRecap');
const MintViewWizardMetadata = loadView('mint/wizard/MintViewWizardMetadata');

export const mintRoute = {
  path: ROUTES.PATH.MINT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.MINT,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.MINT,
      component: MintViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.MINT_DASHBOARD,
      name: ROUTES.NAME.MINT_DASHBOARD,
      component: MintViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.MINT_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.MINT_NEW,
      name: ROUTES.NAME.MINT_NEW,
      component: MintViewNew,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.MINT_NEW,
      },
    },
    {
      path: ROUTES.PATH.MINT_NFT,
      name: ROUTES.NAME.MINT_NFT,
      component: MintViewNft,
      props: true,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.MINT_NFT,
      },
    },
    {
      path: ROUTES.PATH.MINT_WIZARD,
      component: MintViewWizard,
      props: (route) => ({ ...route.params }),
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.MINT_WIZARD,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.MINT_WIZARD,
          props: (route) => ({ ...route.params }),
          component: MintViewWizardBasicData,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.MINT_WIZARD_ATTRIBUTES,
          name: ROUTES.NAME.MINT_WIZARD_ATTRIBUTES,
          props: (route) => ({ ...route.params }),
          component: MintViewWizardAttributes,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.MINT_WIZARD_ATTRIBUTES,
          },
        },
        {
          path: ROUTES.PATH.MINT_WIZARD_BASIC_DATA,
          name: ROUTES.NAME.MINT_WIZARD_BASIC_DATA,
          props: (route) => ({ ...route.params }),
          component: MintViewWizardBasicData,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.MINT_WIZARD_BASIC_DATA,
          },
        },
        {
          path: ROUTES.PATH.MINT_WIZARD_RECAP,
          name: ROUTES.NAME.MINT_WIZARD_RECAP,
          props: (route) => ({ ...route.params }),
          component: MintViewWizardRecap,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.MINT_WIZARD_RECAP,
          },
        },
        {
          path: ROUTES.PATH.MINT_WIZARD_METADATA,
          name: ROUTES.NAME.MINT_WIZARD_METADATA,
          props: (route) => ({ ...route.params }),
          component: MintViewWizardMetadata,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.MINT_WIZARD_METADATA,
          },
        },
      ],
    },
  ],
};
